import React from 'react';
import pt from 'prop-types';
import Text from '/src/components/ui/text';
import Link, { linkProptypes } from '../../link';
import Image, { imageProptypes } from '../../image';
import { combine } from '/src/helpers/styles';
import Icon from '../../icon';
import * as styles from './styles.module.scss';

const DownloadTeaserItem = ({
  image,
  title,
  link,
  isInSlider,
  className,
  type,
}) => {
  const renderMedia = () => {
    if (type === 'Image') {
      return (
        <Image
          image={image}
          className={styles.imgWrapper}
          imgClassName={styles.img}
          disableImageCaption
        />
      );
    }
    if (type === 'PDF') {
      return (
        <div className={styles.imgWrapper}>
          <Icon name="file-pdf" size="4x" className={styles.icon} />
        </div>
      );
    }
    return (
      <div className={styles.imgWrapper}>
        <Icon name="file-archive" size="4x" className={styles.icon} />
      </div>
    );
  };

  return (
    <Link
      link={link}
      className={combine(
        styles.itemWrapper,
        isInSlider && styles.inSlider,
        className
      )}
    >
      {renderMedia()}
      <div className={styles.textWrapper}>
        {title && (
          <Text as="span" type="p1" variant="semibold">
            {title}
          </Text>
        )}
      </div>
      <Icon name="download" size="2x" className={styles.icon} />
    </Link>
  );
};

DownloadTeaserItem.propTypes = {
  image: pt.shape(imageProptypes),
  title: pt.string.isRequired,
  link: pt.shape(linkProptypes).isRequired,
  isInSlider: pt.bool,
  className: pt.string,
  type: pt.string,
};

DownloadTeaserItem.defaultProps = {
  image: null,
  type: 'Image',
  className: '',
  isInSlider: false,
};

export default DownloadTeaserItem;
