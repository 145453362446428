import React from 'react';
import pt from 'prop-types';
import Container from '../../../modules/container';
import DownloadTeaserItem from '../../ui/downloadTeaser/teaserItem';
import Headline, { headlinePropTypes } from '../../ui/headline';
import * as styles from './styles.module.scss';

const PressReleaseDownload = ({ headline, files }) => {
  return (
    <Container className={styles.wrapper}>
      <Headline leve="h1" type="h1" text={headline} className={styles.title} />
      <div className={styles.filesWrapper}>
        {files.map((item, index) => (
          <DownloadTeaserItem
            key={index}
            link={{
              externalLink: item.document.document
                ? item.document.document.file.url
                : item.document.image.file.url,
            }}
            title={
              item.document.document
                ? item.document.document.title
                : item.document.image.description
            }
            image={item.document.image ? item.document.image : null}
            type={item.type}
          />
        ))}
      </div>
    </Container>
  );
};

PressReleaseDownload.propTypes = {
  headline: pt.shape(headlinePropTypes),
  files: pt.array,
};

PressReleaseDownload.defaultProps = {
  files: [],
};

export default PressReleaseDownload;
